<template>
   <div>
      <div class="container">
         <div class="row justify-content-md-center">
            <div class="col-12 col-lg-8 account-1 mt-4">
               <a href="#" v-if="!isServerActivated" @click="$event.preventDefault(); showTermsModalFlow()">
               {{referralProgramOnCheckbox ? "Cancel" : "Set Up Referral Program"}}
               </a>
               <div class="mt-3" v-if="didAgreeToTerms">
                  <a
                     href="referral#"
                     @click="$event.preventDefault()"
                     data-toggle="modal"
                     data-target="#referral-terms-modal2"
                     v-b-modal.referral-terms-modal2
                     >
                        Referral Program - Terms of Service
                     </a>
                  <hr>
               </div>
            </div>

            <div class="col-12 col-lg-8 account-1 mt-3 referal-new" v-if="referralProgramOnCheckbox && didAgreeToTerms && !isServerActivated">
             <h5>Select Your Referral Code</h5>
             <form class="form-inline mt-3">
               <input type="text" class="form-control mb-2 mr-sm-2" v-model="slug" id="inlineFormInputName2" placeholder="Referral Code">
               <button type="submit" @click="$event.preventDefault(); enableReferral()" class="btn btn-primary mb-2" id="referal-btn">Activate</button>
               <p class="text-danger">{{slugSaveErrorMsg}}</p>
             </form>
             <hr>
            </div>

            <div class="col-12 col-lg-8 account-1 mt-3 referral-new" v-if="isServerActivated">
               <h5>Referral Link: </h5>
               <p>{{shareUrl}}</p>
               <div>
                  <a @click="copyLinkToClipboard" style="color: #007bff !important;"><i class="bi bi-clipboard p-2"></i></a>
                  <a target="_blank" :href="twitterShareUrl"><i class="bi bi-twitter p-2"></i></a>
                  <a target="_blank" :href="linkedInShareUrl"><i class="bi bi-linkedin p-2"></i></a>
                  <div class="blue p-3" v-if="isShowingCopiedFeedback">
                     Copied!
                  </div>
               </div>
               <p>
                  <a
                     href="referral-link#"
                     @click="$event.preventDefault()"
                     data-toggle="modal"
                     data-target="#referral-slug-modal"
                     v-b-modal.referral-slug-modal
                  >
                     Update Referral Link
                  </a>
               </p>
               <hr>
               <p>
                  Twitter Handle: <strong>@{{lastSavedTwitterHandle}}</strong>
               </p>
               <span style="float:right; font-size: 0.9em;" class="bi bi-check-circle-fill text-success" v-if="lastSavedTwitterHandle !== '' && !twitterHandleSaveErrorMsg">&nbsp;Recorded</span>

 
               <div v-if="!lastSavedTwitterHandle">
                  <h5>Enter Your Twitter Handle</h5>
                  <form class="form-inline mt-3">
                     <input type="text" class="form-control mb-2 mr-sm-2" v-model="twitterHandle" id="inlineFormInputName2" placeholder="Twitter Handle">
                     <button type="submit" @click="$event.preventDefault(); setTwitterHandle()" class="btn btn-primary mb-2" id="referal-btn">Activate</button>
                     <p class="text-danger">{{twitterHandleSaveErrorMsg}}</p>
                  </form>
               </div>

               <p v-else>
                  <a
                     href="twitter-handle#"
                     @click="$event.preventDefault()"
                     data-toggle="modal"
                     data-target="#twitter-handle-modal"
                     v-b-modal.twitter-handle-modal
                  >
                     Update Twitter Handle
                  </a>
               </p>
               <hr>

               <h6 class="text-danger" v-if="USDCAddress === ''">
                  <i class="bi bi-exclamation-octagon-fill"></i>
                  Payment Information Needed
               </h6>
               <p>
                  Wallet ID: {{USDCAddressShort}}
               </p>
               <span style="float:right; font-size: 0.9em;" class="bi bi-check-circle-fill text-success" v-if="USDCAddress !== '' && USDCErrorMessage === ''">&nbsp;Recorded</span>
               <a
                  href="referral#"
                  @click="$event.preventDefault()"
                  data-toggle="modal"
                  data-target="#referral-wallet-modal"
                  v-b-modal.referral-wallet-modal
               >
                  Update Payment Information
               </a>
               <div class="text-danger">{{USDCErrorMessage}}</div>

               <hr>

               <div class="mt-3 monthly-referral-earnings" v-if="earnings.length > 0">
                  <h5 class="d-inline">{{earnings[0]["date_display"]}} Referral Earnings: ${{Number(earnings[0]["amount"]).toFixed(2)}}</h5>

                  <span v-if="earnings[0]['transactions']">
                     (&nbsp;<template v-for="txItem in (earnings[0]['transactions'].match(/0x[0-9a-fA-F]+/g))"><a v-bind:key="txItem" target="_blank" :href="'https://etherscan.io/tx/' + txItem">tx</a>&nbsp;</template>)
                  </span>

                  <span style="font-size: 0.9em;" class="bi bi-check-circle-fill text-success ml-3 float-right" v-if="earnings[0]['is_paid']">&nbsp;Paid</span>
               </div>

               <div class="mt-3 past-earnings" v-if="earnings.length > 1">
                  <h6><a href="referral#" id="past-earnings-btn" @click="$event.preventDefault(); viewPastEarnings = !viewPastEarnings">View Past Earnings</a></h6>
               </div>

               <div class="mt-3 past-earnings-log mb-5" v-if="viewPastEarnings && earnings.length > 1">
                  <ul>
                     <li v-for="value in earnings.slice(1)" v-bind:key="value['date_display'] + 'row'">
                        {{value["date_display"]}}: ${{Number(value["amount"]).toFixed(2)}}

                        <span v-if="value['transactions']">
                           (&nbsp;<template v-for="txItem in (value['transactions'].match(/0x[0-9a-fA-F]+/g))"><a v-bind:key="txItem" target="_blank" :href="'https://etherscan.io/tx/' + txItem">tx</a>&nbsp;</template>)
                        </span>

                        <span class="bi bi-check-circle-fill text-success ml-3 float-right" v-if="value['is_paid']">&nbsp;Paid</span>
                     </li>
                  </ul>
               </div>
               <div class="row add-bottom-scroll-padding">&nbsp;</div>
            </div><!--END ROW-->
         </div><!--END REFERRAL-MAIN-->
      </div><!--END CONTAINER-->

      <b-modal class="modal" tabindex="-1" role="dialog" id="referral-terms-modal" centered>
         <template #modal-title>Referral Program Terms of Service</template>
         <div class="modal-body text-left" style="max-height: 400px; overflow-y: scroll">
            <div class="row">
              <div class="col-12 text-center">
                <h4>Referal Program Terms</h4>
              </div>

              <div class="col-12">
                <p>Program Overview</p>

                <p>1. Sign Up</p>
                <p>2. Share Your Xecret.io Referral Link</p>
                <p>3. New Users Will Receive $5 off Xecret.io Paid Plans</p>
                <p>4. You receive 30% commission on Initial Transaction</p>
                <p>5. Monthly Payments to your USDC Wallet Address</p>

                <br>

                <h3>1. Signing Up</h3>
                <p>By signing up, you agree to the Xecret.io Terms of Service.</p>

                <br>

                <h3>2. Sharing Your Referral Link</h3>
                <p>You may share your Referral Link on your website and social media channels consistent with any Xecret.io marketing guidelines.
                You may not share our referral link via email unless you have prior written consent from us and then only subject to compliance
                with the CAN-SPAM Act and state email laws.</p>

                <p>You agree not to engage in any of the following prohibited practices: (i) creating fake accounts, blogs, web pages, profiles,
                  websites, links or messages;(ii) posting your referral link on any page that is not owned and controlled by you, including, but
                  not limited to, any merchant, Facebook, or forum page; (iii) bidding on any keywords containing “Xecret”; (iv) use of any Xecret
                  trademarks or materials other than as provided; (v) paid advertising for the purpose of generating traffic directly to your
                  referral link; (vi) engaging in conduct that is misleading as your relationship with Xecret; (vii) taking any action or making
                  any content that is disparaging or defamatory to us; (viii) failing to disclose your relationship with Xecret; or (ix) engaging
                  in any conduct prohibited by law.  Note: Multiple accounts created with the same name, address, email address or other identifying
                  feature may be flagged as fraudulent referrals. </p>

                <p>You further agree to comply with all applicable Federal Trade Commission advertising and endorsement guidelines.  See The FTC’s
                Endorsement Guides: Being Up-Front With Consumers (https://www.ftc.gov/tips-advice/business-center/guidance/ftcs-endorsement-guides-what-people-are-asking)
                and The FTC Guides the Use of Endorsements and Testimonials in Advertising (https://www.ftc.gov/news-events/media-resources/truth-advertising/advertisement-endorsements).</p>

                <br>

                <h3>3. Qualified Referrals</h3>
                <p>Qualified referrals are customers who purchase a Xecret.io paid plan via the referral link who have not previously registered with Xecret.io.
                They will receive $5 off their first order and you will receive thirty percent (30%) on that first order.  Any chargebacks by referred customers
                will result in a loss of commission.</p>

                <br>

                <h3>4. Payment</h3>
                <p>Once your commission total reaches $50, it will be paid to your USDC Wallet Address in U.S. dollars or crypto currency.  Please be sure to include your
                correct correct wallet address as We assume no liability for payments sent to an incorrect address.</p>

                <br>

                <h3>5. Reservation of Rights</h3>
                <p>Xectret.io reserves the right, in our sole discretion, to withhold, deny or cancel any commission and/or terminate your account if it determines, in its sole
                discretion, that such a referral was fraudulent, abusive, unethical, suspicious or otherwise inconsistent with these Program Terms, the Terms of Use and/or
                Privacy Policy, or any other applicable law or regulation. Our decisions are final.</p>
              </div>
            </div>
          </div>
         <template #modal-footer>
            <button
               type="button"
               class="btn btn-primary"
               @click="$bvModal.hide('referral-terms-modal'); markTermsAgreed()"
               data-dismiss="modal"
               id="referral-accept"
               style="margin: 10px 10px 10px 10px;"
            >
               Accept
            </button>
            <button
               type="button"
               class="btn btn-secondary"
               @click="$bvModal.hide('referral-terms-modal'); markTermsDisagreed()"
               data-dismiss="modal"
               id="referral-no-accept"
               style="margin: 10px 10px 10px 10px;"
            >
               Do Not Accept
            </button>
         </template>
      </b-modal>

      <b-modal class="modal" tabindex="-1" role="dialog" id="referral-terms-modal2" centered hide-footer>
         <template #modal-title>Referral Program Terms of Service</template>
         <div class="modal-body text-left">
            <div class="row">
              <div class="col-12 text-center">
                <h4>Referal Program Terms</h4>
              </div>

              <div class="col-12">
                <p>Program Overview</p>

                <p>1. Sign Up</p>
                <p>2. Share Your Xecret.io Referral Link</p>
                <p>3. New Users Will Receive $5 off Xecret.io Paid Plans</p>
                <p>4. You receive 30% commission on Initial Transaction</p>
                <p>5. Monthly Payments to your USDC Wallet Address</p>

                <br>

                <h3>1. Signing Up</h3>
                <p>By signing up, you agree to the Xecret.io Terms of Service.</p>

                <br>

                <h3>2. Sharing Your Referral Link</h3>
                <p>You may share your Referral Link on your website and social media channels consistent with any Xecret.io marketing guidelines.
                You may not share our referral link via email unless you have prior written consent from us and then only subject to compliance
                with the CAN-SPAM Act and state email laws.</p>

                <p>You agree not to engage in any of the following prohibited practices: (i) creating fake accounts, blogs, web pages, profiles,
                  websites, links or messages;(ii) posting your referral link on any page that is not owned and controlled by you, including, but
                  not limited to, any merchant, Facebook, or forum page; (iii) bidding on any keywords containing “Xecret”; (iv) use of any Xecret
                  trademarks or materials other than as provided; (v) paid advertising for the purpose of generating traffic directly to your
                  referral link; (vi) engaging in conduct that is misleading as your relationship with Xecret; (vii) taking any action or making
                  any content that is disparaging or defamatory to us; (viii) failing to disclose your relationship with Xecret; or (ix) engaging
                  in any conduct prohibited by law.  Note: Multiple accounts created with the same name, address, email address or other identifying
                  feature may be flagged as fraudulent referrals. </p>

                <p>You further agree to comply with all applicable Federal Trade Commission advertising and endorsement guidelines.  See The FTC’s
                Endorsement Guides: Being Up-Front With Consumers (https://www.ftc.gov/tips-advice/business-center/guidance/ftcs-endorsement-guides-what-people-are-asking)
                and The FTC Guides the Use of Endorsements and Testimonials in Advertising (https://www.ftc.gov/news-events/media-resources/truth-advertising/advertisement-endorsements).</p>

                <br>

                <h3>3. Qualified Referrals</h3>
                <p>Qualified referrals are customers who purchase a Xecret.io paid plan via the referral link who have not previously registered with Xecret.io.
                They will receive $5 off their first order and you will receive thirty percent (30%) on that first order.  Any chargebacks by referred customers
                will result in a loss of commission.</p>

                <br>

                <h3>4. Payment</h3>
                <p>Once your commission total reaches $50, it will be paid to your USDC Wallet Address in U.S. dollars or crypto currency.  Please be sure to include your
                correct correct wallet address as We assume no liability for payments sent to an incorrect address.</p>

                <br>

                <h3>5. Reservation of Rights</h3>
                <p>Xectret.io reserves the right, in our sole discretion, to withhold, deny or cancel any commission and/or terminate your account if it determines, in its sole
                discretion, that such a referral was fraudulent, abusive, unethical, suspicious or otherwise inconsistent with these Program Terms, the Terms of Use and/or
                Privacy Policy, or any other applicable law or regulation. Our decisions are final.</p>
              </div>
            </div>
          </div>
      </b-modal>

      <b-modal class="modal" tabindex="-1" role="dialog" id="referral-wallet-modal" centered hide-footer>
         <template #modal-title>Enter Your Public Wallet Address</template>
         <p>Note: Only enter an address that can receive USDC</p>

         <form class="form mt-3">
            <input type="text" class="form-control mb-2 mr-sm-2" placeholder="Wallet Address" v-model="USDCAddressField">
            <button
               type="submit"
               @click="$event.preventDefault(); $bvModal.hide('referral-wallet-modal'); setUSDCAddress()"
               class="btn btn-lg btn-primary btn-block mb-4 mt-4"
               id="referral-btn"
            >
               Save
            </button>
         </form>
      </b-modal>

      <b-modal class="modal" tabindex="-1" role="dialog" id="referral-slug-modal" centered hide-footer>
         <template #modal-title></template>
         <h3>Customize referral link.</h3>
         <p>Note: Changing your referral link will cause the old link to no longer work</p>

         <form class="form mt-3">
            <input type="text" class="form-control mb-2 mr-sm-2" placeholder="Personalized Text" v-model="slug">
            <p>
            New link: {{`${referralLinkPrefix}r=${slug}`}}
            </p>
            <p class="text-danger">{{slugSaveErrorMsg}}</p>
            <button
               type="submit"
               @click="$event.preventDefault(); setSlug();"
               class="btn btn-lg btn-primary btn-block mb-4 mt-4"
               id="save-btn"
            >
               Save
            </button>
         </form>
      </b-modal>

      <b-modal class="modal" tabindex="-1" role="dialog" id="twitter-handle-modal" centered hide-footer>
         <template #modal-title></template>
         <h3>Enter Twitter Handle.</h3>

         <form class="form mt-3">
            <input type="text" class="form-control mb-2 mr-sm-2" placeholder="Twitter Handle" v-model="twitterHandle">
            <p class="text-danger">{{twitterHandleSaveErrorMsg}}</p>
            <button
               type="submit"
               @click="$event.preventDefault(); setTwitterHandle();"
               class="btn btn-lg btn-primary btn-block mb-4 mt-4"
               id="save-btn"
            >
               Save
            </button>
         </form>
      </b-modal>
   </div>
</template>

<script>
export default {
   name: "Referral",
   data() {
      return {
         isServerActivated: false,
         referralProgramOnCheckbox: false,
         didAgreeToTerms: false,
         enabledReferralProgram: false,
         USDCAddress: "",
         USDCAddressField: "",
         USDCErrorMessage: "",
         slug: "",
         slugSaveErrorMsg: "",
         viewPastEarnings: false,
         earnings: [],
         isShowingCopiedFeedback: false,
         lastSavedSlug: "",
         twitterHandle: "",
         lastSavedTwitterHandle: "",
         twitterHandleSaveErrorMsg: "",
      }
   },
   computed: {
      twitterShareUrl: function() {
         return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareUrl)}`;
      },
      linkedInShareUrl: function() {
         return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.shareUrl)}`;
      },
      shareUrl: function() {
         return "https://" + window.location.host + "/?r=" + this.slug;
      },
      isValidSlug: function() {
         return this.slugErrorMessage !== "";
      },
      slugErrorMessage: function() {
         if (!/^[-a-zA-Z0-9_]+$/.test(this.slug)) {
            return "A referral code must consist of letters, numbers, hyphen, and underscore only.";
         }
         return "";
      },
      USDCAddressShort: function() {
         let result = this.USDCAddress;
         if (result.length > 8) {
            result = result.substring(0, 5) + "..." + result.substring(result.length - 5);
         }
         return result;
      },
      referralLinkPrefix: function() {
         return `https://${window.location.host}/?`;
      }
   },
   mounted() {
      window.gtag("event", "ReferralPage");
      // get account data and populate state
      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.USDCAddress = response.data.usdc_address;
            this.slug = response.data.referral_slug;
            this.lastSavedSlug = this.slug;
            this.enabledReferralProgram = response.data.is_referral_enabled;
            this.isServerActivated = response.data.is_referral_enabled;
            this.referralProgramOnCheckbox = this.isServerActivated;
            this.earnings = response.data.earnings;
            this.lastSavedTwitterHandle = response.data.twitter_handle;
            this.twitterHandle = response.data.twitter_handle;
         })
         .catch((error) => console.error(error));

   },
   methods: {
      showTermsModalFlow() {
         if (this.isServerActivated) {
            this.referralProgramOnCheckbox = true;
         }            
         else if (!this.referralProgramOnCheckbox) {
            this.$bvModal.show('referral-terms-modal');
            this.referralProgramOnCheckbox = true;
         }
         else {
            this.referralProgramOnCheckbox = false;
            this.didAgreeToTerms = false;
         }
      },
      copyLinkToClipboard() {
         navigator.clipboard.writeText(this.shareUrl);
         this.isShowingCopiedFeedback = true;
         setTimeout(() => this.isShowingCopiedFeedback = false, 2000);
      },
      setSlug() {
         this.$bvModal.hide("referral-slug-modal");
         this.slugSaveErrorMsg = "";
         const toSend = {
            "slug": this.slug
         }
         this.axios
            .post("/api/update-referral", toSend, { withCredentials: true })
            .then((response) => {
               if (response.data && response.data.status == "success") {
                  this.slug = response.data.slug;
                  this.lastSavedSlug = this.slug;
                  this.$bvModal.hide('referral-slug-modal'); 
               }
               else {
                  this.slug = this.lastSavedSlug;
                  this.slugSaveErrorMsg = response.data.status || "Failed to save referral code. Please try again, or try a different one.";
               }
            })
            .catch((error) => console.error(error));

      },
      setTwitterHandle() {
         this.twitterHandleSaveErrorMsg = "";
         if (this.twitterHandle.startsWith("@")) {
            this.twitterHandle = this.twitterHandle.substring(1);
         }
         const toSend = {
            "twitterHandle": this.twitterHandle
         }
         this.axios
            .post("/api/update-twitter-handle", toSend, { withCredentials: true })
            .then((response) => {
               if (response.data && response.data.status == "success") {
                  this.lastSavedTwitterHandle = this.twitterHandle;
                  this.$bvModal.hide('twitter-handle-modal'); 
               }
               else {
                  this.twitterHandle = this.lastSavedTwitterHandle;
                  this.twitterHandleSaveErrorMsg = response.data.status || "Failed to save twitter handle. Please check your handle of the form @twitterhandle, and try again.";
               }
            })
            .catch((error) => console.error(error));

      },
      markTermsAgreed() {
         this.referralProgramOnCheckbox = true;
         this.didAgreeToTerms = true;
      },
      markTermsDisagreed() {
         this.didAgreeToTerms = false;
         this.referralProgramOnCheckbox = false;
      },
      restoreOldSlug() {
         this.slug = this.lastSavedSlug;
      },
      setUSDCAddress() {
         const toSend = {
            "usdc_address": this.USDCAddressField
         }
         this.axios
            .post("/api/update-referral", toSend, { withCredentials: true })
            .then((response) => {
               this.USDCAddress = this.USDCAddressField;
               this.USDCErrorMessage = "";
               console.log(response.data);
            })
            .catch((error) => {
               this.USDCErrorMessage = "Failed to save your payment address. Please check your address and try again.";
               console.error(error);
               this.USDCAddressField = "";
            });

      },
      enableReferral() {
         const toSend = {
            "is_referral_enabled": true,
            "slug": this.slug
         }
         this.axios
            .post("/api/update-referral", toSend, { withCredentials: true })
            .then((response) => {
               if (response.data && response.data.status == "success") {
                  this.slug = response.data.slug;
                  this.lastSavedSlug = this.slug;
                  this.isServerActivated = true;
               }
               else {
                  this.slugSaveErrorMsg = response.data.status || "Failed to save referral code. Please try again, or try a different one.";
               }
            })
            .catch((error) => console.error(error));
      },
      disableReferral() {
         this.referralCheckbox = false;
         this.didAgreeToTerms = false;
      }
   }
}
</script>

